
import React from "react";

const PricingPage = () => {
  return (
    <div>
    
    <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
    <stripe-pricing-table pricing-table-id="prctbl_1NLEJCJVMaa2P7NA9JbUBn6F"
    publishable-key="pk_test_51NIN4vJVMaa2P7NApvN9RycLfe5FhhhCvvJkKmGbeCK1gIRNzxH0bvyKVH4TOKPFNdYWRVr6JD0Ad6O8kuI2cm5a00zUcKcaxC">
    </stripe-pricing-table>
    </div>
  );
};

export default PricingPage;
