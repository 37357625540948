import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Landing from './Landing'
import { PrivacyPolicy } from './pages/PrivacyPolicy'
import PricingPage from './pages/PricingPage'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AITokenProvider } from './AITokenContext';
import { handleGetUuid } from './utils/uniqueid';

export default function App() {
    const userInfo = localStorage.getItem("userInfo");
    // console.log(`userInfo: ${userInfo}`);
    const email = JSON.parse(userInfo)?.email;
    const uuid = localStorage.getItem("_og_id");
    if (uuid === null || uuid === 'undefined') {
        // console.log("uuid is null, setting it")
        handleGetUuid();
    }

    return (
        <AITokenProvider email={email} uuid={uuid}>
        <GoogleOAuthProvider clientId="790432930561-qdaes5l0p03o0ihhdhnnlpchmao733jj.apps.googleusercontent.com">
        
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/auth/google" element={<Landing />} />
        </Routes>
        </BrowserRouter>
        
        </GoogleOAuthProvider>
        </AITokenProvider>
    )
    }
