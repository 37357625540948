import React, { useEffect, useState, useRef } from "react";
import AIContextList from "./components/AIContextList";
import { StyledTextarea } from "./styled/StyledTextArea";



export default function PaperNote({
  showPricingPage,
  summarizedNotes,
  tldrNotes,
  bulletPointNotes,
  urlSummary,
  urlTldr,
  urlBulletPoints,  
  
  handleNoteTone,
  handleNoteType,
  handleProcessingOptions,

  handleCurrentSentence,  
  revisedNote,  
  
  processingOptions,

  handleNoteBuffer,
  clearNotes,
  
}) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isSummarizedNotesVisible, setIsSummarizedNotesVisible] =
    useState(false);
  const [isTldrNotesVisible, setIsTldrNotesVisible] = useState(false);
  const [isBulletPointNotesVisible, setIsBulletPointNotesVisible] =
    useState(false);
  const inputRef = useRef(null);
  const inputOrigRef = useRef(null);

useEffect(() => {
  inputRef.current.value = revisedNote;
}, [revisedNote])


  useEffect(() => {
    if (summarizedNotes?.length > 0 || urlSummary?.length > 0) {
      setIsSummarizedNotesVisible(true);
      setIsMenuVisible(false);
    } else {
      setIsSummarizedNotesVisible(false);
    }
  }, [summarizedNotes, urlSummary]);

  useEffect(() => {
    if (tldrNotes?.length > 0 || urlTldr?.length > 0) {
      setIsTldrNotesVisible(true);
      setIsMenuVisible(false);
    } else {
      setIsTldrNotesVisible(false);
    }
  }, [tldrNotes, urlTldr]);

  useEffect(() => {
    if (bulletPointNotes?.length > 0 || urlBulletPoints?.length > 0) {
      setIsBulletPointNotesVisible(true);
      setIsMenuVisible(false);
    } else {
      setIsBulletPointNotesVisible(false);
    }
  }, [bulletPointNotes, urlBulletPoints]);

  useEffect(() => {
    // focus textarea on mount
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    // if context options change back to false then focus textarea
    if (!isMenuVisible) {
      inputRef.current.focus();
    }
  }, [isMenuVisible]);

  function handleMenuVisible(value) {    
    setIsMenuVisible(value);
  }

  function handleSetFocus() {
    inputRef.current.focus();
    if (inputRef.current.value.startsWith("/")) {
      inputRef.current.value = "";
    }
    if (inputRef.current.value.toLowerCase().includes("f/")) {
      inputRef.current.value = inputRef.current.value.replace("f/", "");
    }
  }

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      handleNoteBuffer(inputRef.current.value);
    }
  };

  const handleLineEnter = event => {
    if (event.key === "Enter" ) { //|| event.key === "Tab"   
      // debugger;
      // if (!processingOptions.includes(event.target.textContent)) {
      //   console.log("yo! handling processing options for " + event.target.textContent);
      //   // handleNoteType(event.target.textContent);
      //   handleProcessingOptions(event.target.textContent);
      // }

      console.log("Enter or Tab pressed because of " + event.key)
      if (inputRef.current && inputRef.current.contains(event.target)) {                
        handleCurrentSentence(inputRef.current.value);             
        var sentences = inputRef.current.value.split('\n')
        var last_sentence = sentences[sentences.length - 1]  
        inputOrigRef.current.value = inputOrigRef.current.value + '\n' + last_sentence;        
        // const tokenUsage = refreshAITokenUsageInfo;
        // setAITokenUsage(refreshAITokenUsageInfo);
      }
    }
  } 
 

  useEffect(() => {
    if (processingOptions.includes("AI Inline")) {
      document.addEventListener("keydown", handleLineEnter)
    }
      // cleanup
    return () => {
      document.removeEventListener("keydown", handleLineEnter) 
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingOptions]);
  

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside) 
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  


  useEffect(() => {
    const notesArea = document.getElementById("paperNote");
    if (clearNotes) {
      notesArea.value = "";
    }
  }, [clearNotes]);

  function handleTextChange(event) {
    // Watch for 'f/' or '/' to show menu
    if (event.target.value.startsWith("/")) {
      handleMenuVisible(true);

      const element = document.getElementById("contextList");
      if (element) {
        window.setTimeout(() => element.focus(), 0);
      }
    } else if (event.target.value.toLowerCase().includes("f/")) {
      handleMenuVisible(true);
    } 
    // else if (event.target.value.toLowerCase().includes('url/')) {
    //   processUrl(event.target.value.split("url/")[1]);
    // }
  }

  // "<br>You can type '/' to select a tone and type of meeting.<br> Type a name followed by a colon to denote a speaker.&#10;Subsequent references to the same speaker can be abbreviated with a single letter. Example, 'John: Hello, my name is John.  Mary: Hi John, my name is Mary.  J: Nice to meet you Mary.'"

  return (
    <>
      <StyledTextarea
        id="paperNote"
        ref={inputRef}
        multiline="true"
        rows="200"
        aria-label="empty textarea"
        placeholder="Start typing some notes or a webpage URL. You can also type 'f/' for commands..."
        onChange={handleTextChange}  
        sx={{visibility:
          showPricingPage ? "hidden" : "visible",
        display: showPricingPage ? "none" : "",}}      
      />
      {processingOptions.includes("AI Inline") && (
        <>
        {/* <Typography variant="body1" style={{fontStyle:"italic", visibility: showPricingPage?"hidden":"visible", display: showPricingPage?"hidden":"visible"}}>    Original</Typography> */}
        <StyledTextarea
          id="revisedPaperNote"
          ref={inputOrigRef}
          aria-label="empty textarea"          
          placeholder="Inline processing is enabled. Your original text will appear here."     
          sx={{marginTop:"1em", visibility:
            showPricingPage ? "hidden" : "visible",
          display: showPricingPage ? "none" : "",
          borderStyle:"dashed",
          borderColor:"#4786e4",
          color:"#4786e4",
        }}              
        />
        </>
      )}
      {(summarizedNotes?.length > 0 || urlSummary?.length > 0) && (
        <>
        <h2>Summary</h2>
        <StyledTextarea
          sx={{
            display: isSummarizedNotesVisible ? "block" : "none",
            visibility: isSummarizedNotesVisible ? "visible" : "hidden",
            backgroundColor: "lightyellow",
            marginBottom: "1em",
            marginRight: "1em",
          }}
          value={summarizedNotes.length > 0 ? summarizedNotes : urlSummary}
        />
        </>
      )}
      {(tldrNotes?.length > 0 || urlTldr?.length > 0) && ( 
        <>
        <h2>TL;DR</h2>
        <StyledTextarea
          sx={{
            display: isTldrNotesVisible ? "block" : "none",
            visibility: isTldrNotesVisible ? "visible" : "hidden",
          }}
          value={tldrNotes.length > 0 ? tldrNotes : urlTldr}
        />
        </>
      )}

      {(bulletPointNotes?.length > 0|| urlBulletPoints?.length > 0) && (
         <>
         <h2>Bullet Points</h2>
        <StyledTextarea
          sx={{
            display: isBulletPointNotesVisible ? "block" : "none",
            visibility: isBulletPointNotesVisible ? "visible" : "hidden",
          }}
          value={bulletPointNotes.length > 0 ? bulletPointNotes : urlBulletPoints}
        />
        </>
      )}

      {isMenuVisible && (
        <AIContextList
          isMenuVisible={isMenuVisible}
          handleSetFocus={handleSetFocus}
          handleMenuVisible={handleMenuVisible}
          handleNoteType={handleNoteType}
          handleNoteTone={handleNoteTone}
          handleProcessingOptions={handleProcessingOptions}
        />
      )}
    </>
  );
}
