import axios from 'axios';

export const fetchPDF = (filename) => { 
  axios({
    method: 'GET',
    url: `${process.env.REACT_APP_FASTNOTES_BE}/api/pdf?filename=${filename}`,
    responseType: 'blob',  // Important: set the response type to 'blob'
  })
    .then(response => {      
      console.log(response.data)
      const fileURL = URL.createObjectURL(new Blob([response?.data], { type: 'application/pdf' }));
      console.log(fileURL);
      window.open(fileURL);  // Open the PDF file in a new browser tab         
      return fileURL;
    })    
    .catch(error => {
      console.error(`Error fetching PDF, ERROR: ${error}`);
      return "error 16";
    });
};



    // let url = (window.URL || window.webkitURL || window || {}).createObjectURL(blob);
    // let url = `${process.env.REACT_APP_FASTNOTES_BE}/api/pdf?filename=${filename}`;
    // workaround for mobile playback, where it didn't work on chrome/android.
    // fetch blob at url using xhr, and use url generated from that blob.
    // see issue: https://code.google.com/p/chromium/issues/detail?id=227476
    // thanks, gbrlg    
    // var xhr = new XMLHttpRequest();
    // xhr.open('GET', url, true);
    // xhr.responseType = 'blob';
    // xhr.onreadystatechange = function() {
    //     if (xhr.readyState === 4 && xhr.status === 200) {
    //         var url = (window.URL || window.webkitURL || window || {}).createObjectURL(xhr.response);
    //         return url;
    //         // now url is ready
    //     }
    // };
    // xhr.send();
