import React from "react";
import {Box} from "@mui/material";
import TokenIcon from '@mui/icons-material/Token';

export const TokenIconGroup = ({aITokenUsage, showLoading, showTranscriptionCard}) => {
    return (
        <Box sx={{visibility: showLoading || !showTranscriptionCard ? "hidden" : "visible", display: showLoading || !showTranscriptionCard ? "none" : ""}}>
        <Box sx={{margin:"auto", width: "fit-content", float:"right", paddingLeft:"1rem", marginRight:"2rem"}}>
            <TokenIcon  color='success' sx={{ fontSize: '2rem', width:"100%", margin:"auto" }}  titleAccess='Allocated Tokens'/>
            <Box sx={{display:"block", fontSize:"small", marginBottom:"1rem", width:"100%", margin:"auto"}}>
                {aITokenUsage[0]?.toLocaleString()}
            </Box>
        </Box>

        <Box sx={{margin:"auto", width: "fit-content", float:"right", paddingLeft:"1rem"}}>
            <TokenIcon  color='warning' sx={{ fontSize: '2rem', width:"100%", margin:"auto" }}  titleAccess='Used Tokens'/>
            <Box sx={{display:"block", fontSize:"small", marginBottom:"1rem", width:"100%", margin:"auto"}}>
                {aITokenUsage[1]?.toLocaleString()}
            </Box>
        </Box>

        <Box sx={{margin:"auto", width: "fit-content", float:"right", paddingLeft:"1rem"}}>
            <TokenIcon  color='primary' sx={{ fontSize: '2rem', width:"100%", margin:"auto" }} titleAccess='Remaining Tokens'/>
            <Box sx={{display:"block", fontSize:"small", marginBottom:"1rem", width:"100%", margin:"auto"}}>
                {aITokenUsage[2]?.toLocaleString()}
            </Box>
        </Box>
        </Box>
    )
}
