export function PrivacyPolicy() {
    return (
        <div>
            <h1>Privacy Policy for FastNotes.ai</h1>
            <p>
                This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from fastnotes.ai (the “Site”).            
            </p>
            <h4>Effective Date: June 1, 2023</h4>
            <br/>
            <p>
                At FastNotes.ai, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide while using our software product, FastNotes.ai. Please read this Privacy Policy carefully to understand our practices regarding your personal data.
            </p>
            <br/>
            <h2>Information We Collect:</h2>
            <h3>1.1 Personal Information:</h3>
            <p>
                When you use FastNotes.ai, we may collect certain personal information that you voluntarily provide to us, such as your name, email address, and any other information you provide while using the software.
            </p>
            <br/>
            <h3>1.2 Usage Information:</h3>
            <p>
                We may collect certain information about your usage of FastNotes.ai, including but not limited to your IP address, device information, browser type, operating system, and interactions with the software. This information is collected through cookies and similar technologies.
            </p>
            <br/>
            <h3>Use of Information:</h3>
            2.1 Providing and Improving the Service:
            We use the information we collect to provide and improve FastNotes.ai, personalize your experience, and respond to your inquiries and requests. This includes using OpenAI to summarize the data entered by the user into the form.
            <br/>
            <h3>2.2 Analytics:</h3>
            FastNotes.ai utilizes Google Analytics to collect and analyze usage data to help us understand how the software is being used and to improve its functionality. Google Analytics may collect information such as your IP address and usage patterns. Google Analytics has its own privacy policy, which you can review at [insert link to Google Analytics privacy policy].
            <br/>
            <h3>2.3 Storage of PDF Files:</h3>
            FastNotes.ai stores created PDF files for a period of 24 hours before they are automatically deleted from our servers. This temporary storage allows users to access and download the generated PDF files during that timeframe. After 24 hours, the PDF files are permanently deleted and cannot be recovered.
            <br/>
            Data Sharing and Disclosure:
            <h3>3.1 Service Providers:</h3>
            We may engage trusted third-party service providers to perform functions and provide services to us. These service providers may have access to your personal information solely for the purpose of carrying out their services on our behalf, and they are obligated to maintain the confidentiality and security of your information.
            <br/>
            <h3>3.2 Legal Requirements:</h3>
            We may disclose your personal information if required to do so by law or if we believe that such disclosure is necessary to (a) comply with a legal obligation, (b) protect and defend our rights or property, (c) prevent or investigate possible wrongdoing in connection with FastNotes.ai, (d) protect the safety of users of FastNotes.ai or the public, or (e) detect, prevent, or otherwise address fraud, security, or technical issues.
            <br/>
            <h4>Data Security:</h4>
            We take reasonable measures to protect the security of your personal information and implement appropriate technical and organizational safeguards to prevent unauthorized access, disclosure, alteration, or destruction of your information. However, no data transmission or storage system can be guaranteed to be 100% secure. Therefore, we cannot warrant the absolute security of your information.
            <br/>
            <h4>Your Rights:</h4>
            You have certain rights regarding your personal information under applicable data protection laws. These rights may include the right to access, correct, or delete your personal information. If you would like to exercise any of these rights, please contact us using the contact information provided below.
            <br/>
            <h4>Changes to the Privacy Policy:</h4>
            We reserve the right to modify this Privacy Policy at any time, so please review it periodically. If we make material changes to this Privacy Policy, we will notify you by email or through a notice on FastNotes.ai. Your continued use of FastNotes.ai after any modifications to this Privacy Policy will constitute your acceptance of such changes.
            <br/>
            <h4>Contact Us:</h4>
            <p>FastNotesAI at gmail dot com</p>
            <p>
            If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us
            </p>
        </div>
    )
}
