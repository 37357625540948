function broofa() {
    // console.log("I am in broofa")
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random()*16 | 0, v = c === 'x' ? r : ((r&0x3)|0x8);
        return v.toString(16);
    });
}

// console.log(broofa())

export const handleGetUuid = () => {
    var _og_id 
    var uuid;

    async function getUuid() {                
            _og_id = localStorage.getItem('_og_id');                                                       
        if (_og_id === null || _og_id === 'undefined') {
            uuid = broofa();   
            localStorage.setItem('_og_id', uuid);                                                
        }  
        setTimeout(function() {
            _og_id = localStorage.getItem('_og_id');
        }, 50);    
        var data = uuid;
        return data;
    }
    getUuid();
    return _og_id;
};
