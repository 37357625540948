import React, { useEffect, useRef } from 'react';
// import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { StyledTreeView } from '../styled/StyledMenu';
// import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemText from '@mui/material/ListItemText';
// import Box from '@mui/material/Box';




export default function AIContextList({handleSetFocus, handleMenuVisible, handleNoteType, handleNoteTone, handleProcessingOptions}) {  
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    console.log("close")
    handleMenuVisible(false);
    setOpen(false);
    handleSetFocus();   
  };



  const wrapperRef = useRef(null);
  
  const handleHideDropdown = (KeyboardEvent) => {
    if (KeyboardEvent.key === 'Escape') {
        console.log('escape pressed')
        handleMenuVisible(false);
        setOpen(false);
        handleSetFocus();        
    } 
  //   else if (KeyboardEvent.key === 'Enter') {
  //     console.log('enter pressed')
  //     // debugger;
  //     handleMenuVisible(false);
  //     setOpen(false);
  //     handleSetFocus();        
  // }
  };

  const handleClickOutside = (event) => {    
    if (
        wrapperRef.current && !wrapperRef.current.contains(event.target)
    ) {
        handleMenuVisible(false);
        setOpen(false);
        handleSetFocus();  
    }
};

  useEffect(() => {        
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);    
    // focus on mount
    console.log('AIContextList mounted')    
    
    const element = document.getElementById("contextList");
    if (element) {
      window.setTimeout(() => element.focus(), 0);      
    }
    return () => {
        document.removeEventListener('keydown', handleHideDropdown, true);
        document.removeEventListener('click', handleClickOutside, true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  return (    
    <Dialog onClose={handleClose} open={open} ref={wrapperRef}>
      {/* <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        <nav aria-label="auto ai adjust">
        <List>
            <ListItem disablePadding>
              <ListItemButton>              
                <ListItemText primary="AI Inline" onClick={()=>handleProcessingOptions("AI Inline")} onKeyUp={()=>handleProcessingOptions("AI Inline")}  />
              </ListItemButton>
            </ListItem>
        </List>
        <nav aria-label="processing options">
          <List>
            <ListItem disablePadding>
              <ListItemButton>              
                <ListItemText primary="Summary" onClick={()=>handleProcessingOptions("Summary")} onKeyDown={()=>handleProcessingOptions("Summary")}  />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="TL;DR" onClick={()=>handleProcessingOptions("TL;DR")} onKeyUp={()=>handleProcessingOptions("TL;DR")} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="Bullet Points" onClick={()=>handleProcessingOptions("Bullet Points")} onKeyUp={()=>handleProcessingOptions("Bullet Points")} />
              </ListItemButton>
            </ListItem>    
          </List>
          </nav>
          <nav aria-label="meeting type of note">
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="Interview" onClick={()=>handleProcessingOptions("Interview")} onKeyDown={()=>handleProcessingOptions("Interview")} />
              </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="Team meeting" onClick={()=>handleNoteType("Team meeting")} onKeyUp={()=>handleNoteType("Team meeting")} />
              </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="Board meeting" onClick={()=>handleNoteType("Board meeting")} onKeyUp={()=>handleNoteType("Board meeting")} />
              </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="Client" onClick={()=>handleNoteType("Client")} onKeyUp={()=>handleNoteType("Client")} />
              </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="Training" onClick={()=>handleNoteType("Training")} onKeyUp={()=>handleNoteType("Training")} />
              </ListItemButton>
            </ListItem>
          </List>
          <nav aria-label="tone of note"></nav>
          <List>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary="Informal" onClick={()=>handleNoteTone("Informal")} onKeyUp={()=>handleNoteTone("Informal")} />
              </ListItemButton>
              <ListItemButton>
                <ListItemText primary="Formal" onClick={()=>handleNoteTone("Formal")} onKeyUp={()=>handleNoteTone("Formal")} />
              </ListItemButton>
            </ListItem>
          </List>

          </nav>
        </nav>
      </Box> */}
      <StyledTreeView
        aria-label="FastNotes Options"
        multiSelect={false}
        // onNodeSelect={handleProcessingOptions("Inline")}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ height: 240, flexGrow: 1, width:'auto', maxWidth: 'fit-content', overflowY: 'auto' }}
      >
      <TreeItem nodeId="14" label="Inline"  conClick={()=>handleProcessingOptions("Inline")} aria-label="InlineAI"/>              
      <TreeItem nodeId="10" label="Options">
        <TreeItem nodeId="11" label="Get Summary" onClick={()=>handleProcessingOptions("Summary")} />
        <TreeItem nodeId="12" label="Get TL;DR" onClick={()=>handleProcessingOptions("TL;DR")}/>
        <TreeItem nodeId="13" label="Get Bullet Points" onClick={()=>handleProcessingOptions("Bullet Points")}/>
      </TreeItem>
      <TreeItem nodeId="1" label="Type of Meeting">
        <TreeItem nodeId="2" label="Interview" onClick={()=>handleNoteType("Interview")}/>
        <TreeItem nodeId="3" label="Team meeting"  onClick={()=>handleNoteType("Team meeting")}/>
        <TreeItem nodeId="4" label="Board meeting"  onClick={()=>handleNoteType("Board meeting")}/>
        <TreeItem nodeId="5" label="Client"  onClick={()=>handleNoteType("Client")}/>
        <TreeItem nodeId="6" label="Training"  onClick={()=>handleNoteType("Training")}/>
      </TreeItem>
      <TreeItem nodeId="7" label="Tone of Meeting">
        <TreeItem nodeId="8" label="Informal" onClick={()=>handleNoteTone("Informal")}/>
        <TreeItem nodeId="9" label="Formal"  onClick={()=>handleNoteTone("Formal")} />                  
      </TreeItem>
      <span sx={{fontStyle:"italic", fontSize:".8em"}}>Press 'Esc' to close</span>
    </StyledTreeView>
</Dialog>
  
  );
}