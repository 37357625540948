import React from "react";
import { Button, Tooltip } from "@mui/material";
import { getPostHeaders } from "../utils/headers";
import { fetchPDF } from "../utils/FetchPDF";

export default function PDFButton({
  tldrNotes,
  bulletPointNotes,
  summarizedNotes,
  urlSummary,
  urlTldr,
  urlBulletPoints,
  showLoading,
  showTranscriptionCard,
  handlePdfBytes,
  profile,
  showPricingPage,
}) {
  // async function putPDFInGoogleDrive(pdfBytes) {
  //     await fetch("https://www.googleapis.com/upload/drive/v3/files?uploadType=media", {
  //         method: "POST",
  //         headers: {
  //             Authorization: `Bearer ${profile.accessToken}`,
  //             "Content-Type": "application/pdf",
  //             "Content-Length": pdfBytes.length,
  //         },
  //         body: pdfBytes,
  //     })
  //         .then((response) => response.json())
  //         .then((data) => {
  //             console.log(data);
  //             return data;
  //         })
  //         .catch((error) => {
  //             console.error("Error:", error);
  //         });
  // }

  function doWork() {
    console.log("Creating pdf");
    createPDF();
  }

  async function createPDF() {    
    let headers = getPostHeaders(
      {
        tldr: tldrNotes?.length > 0 ? tldrNotes : urlTldr,
        bullet: bulletPointNotes?.length > 0 ? bulletPointNotes : urlBulletPoints,
        summarized: summarizedNotes?.length > 0 ? summarizedNotes : urlSummary,
        email: profile?.email,
      },
      "POST"
    );
    const response = await fetch(
      `${process.env.REACT_APP_FASTNOTES_BE}/api/to_pdf`,
      { ...headers }
    );
    // if (response.status === 401) {context.logout({})}
    const data = await response.json();
    const pdfurl = fetchPDF(data);
    // putPDFInGoogleDrive(data);
    console.log(`PDF URL: ${pdfurl}`);
    handlePdfBytes(data);
  }

  return (
    <Tooltip
      title={profile?.email ? "Export to PDF" : "Login to enable"}
      placement="bottom"
    >
      <span>
        <Button
          disabled={profile?.email || showPricingPage ? false : true}
          onClick={doWork}
          variant="outlined"
          color="primary"
          sx={{
            width: "100%",
            visibility:
              showLoading || !showTranscriptionCard ? "hidden" : "visible",
            display: showLoading || !showTranscriptionCard ? "none" : "",
          }}
        >
          PDF
        </Button>
      </span>
    </Tooltip>
  );
}
