import React from "react"
import {
    ButtonGroup,
    Paper,
    Tooltip,
    Button,
  } from "@mui/material";
import GoButton from "../components/GoButton"
import PDFButton from "../components/PDFButton";
import { TokenIconGroup } from "./TokenIconGroup";


   
// matches={matches}
//           aITokenUsage={aITokenUsage}
//           showPricingPage={showPricingPage}
//           profile={profile}
//           noteBuffer={noteBuffer}
//           handleNoteBuffer={handleNoteBuffer}
//           handleUrlSummary={handleUrlSummary}
//           handleUrlTldr={handleUrlTldr}
//           handleUrlBulletPoints={handleUrlBulletPoints}
//           handleSummarizedNotes={handleSummarizedNotes}
//           handleTLDRNotes={handleTLDRNotes}
//           handleBulletPointNotes={handleBulletPointNotes}
//           processingOptions={processingOptions}
//           showLoading={showLoading}
//           showTranscriptionCard={showTranscriptionCard}
//           handleClearNotes={setClearNotes}
//           handlePdfBytes={handlePdfBytes}
//           handleSetAITokens={handleSetAITokens}

export default function ActionButtonGroup({
    matches,
    aITokenUsage,
    showPricingPage,
    profile,
    noteBuffer,
    handleNoteBuffer,
    handleUrlSummary,
    handleUrlTldr,
    handleUrlBulletPoints,
    handleSummarizedNotes,
    handleTLDRNotes,
    handleBulletPointNotes,
    processingOptions,
    showLoading,
    showTranscriptionCard,
    handlePdfBytes,
    summarizedNotes,
    tldrNotes,
    bulletPointNotes,
    urlSummary,
    urlTldr,
    urlBulletPoints
    // handleSetAITokens,
    
    // summarizedNotes,
    // tldrNotes,
    // bulletPointNotes,
    // urlSummary,
    // urlTldr,
    // urlBulletPoints,
    // handleUrlSummary,
    // handleUrlTldr,
    // handleUrlBulletPoints,
    // handleSummarizedNotes,
    // handleTLDRNotes,
    // handleBulletPointNotes,    
    // processingOptions,
    // profile,    
    // handlePdfBytes,
    // noteBuffer,   
    // handleNoteBuffer 
}){
    function handleClearNotes() {
        handleNoteBuffer("");
        handleSummarizedNotes([]);
        handleTLDRNotes([]);
        handleBulletPointNotes([]);
        handleUrlSummary([]);
        handleUrlTldr([]);
        handleUrlBulletPoints([]);        
      }

      
    return (
        <Paper elevation={3} sx={{width:"100%", margin:"auto", marginTop:"2rem"}}>
          <ButtonGroup
            size="small"
            aria-label="small button group"
            sx={{ margin: "1em", float:"left"}}
            orientation={`${matches ? `horizontal` : `vertical`}`}
            // orientation="horizontal"
          >
            <GoButton
              showPricingPage={showPricingPage}
              profile={profile}
              noteBuffer={noteBuffer}
              handleUrlSummary={handleUrlSummary}
              handleUrlTldr={handleUrlTldr}
              handleUrlBulletPoints={handleUrlBulletPoints}
              handleSummarizedNotes={handleSummarizedNotes}
              handleTLDRNotes={handleTLDRNotes}
              handleBulletPointNotes={handleBulletPointNotes}
              processingOptions={processingOptions}
              showLoading={showLoading}
              showTranscriptionCard={showTranscriptionCard}   
              // handleSetAITokens={handleSetAITokens}         
            />

            <PDFButton
              tldrNotes={tldrNotes}
              bulletPointNotes={bulletPointNotes}
              summarizedNotes={summarizedNotes}
              urlSummary={urlSummary}
              urlTldr={urlTldr}
              urlBulletPoints={urlBulletPoints}
              showLoading={showLoading}
              showTranscriptionCard={showTranscriptionCard}
              handlePdfBytes={handlePdfBytes}
              profile={profile}
              showPricingPage={showPricingPage}
            />

            <Tooltip title="Clear notes" placement="bottom">
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  visibility:
                    showLoading || !showTranscriptionCard ? "hidden" : "visible",
                  display: showLoading || !showTranscriptionCard ? "none" : "",
                }}
                onClick={handleClearNotes}
              >
                Clear
              </Button>
            </Tooltip>
          </ButtonGroup>
          <TokenIconGroup 
            aITokenUsage={aITokenUsage}
            showLoading={showLoading}
            showTranscriptionCard={showTranscriptionCard}/>
        </Paper>
          
    )
};