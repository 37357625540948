import React, { useEffect } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { Button, Container, Avatar, Tooltip } from "@mui/material";
import axios from "axios";
import { getPostHeaders } from "../utils/headers";
// import { AITokenContext } from "../AITokenContext";
  
function CustomGoogleLoginButton({
  showLoading,
  showTranscriptionCard,
  profile,
  handleSetProfile,  
}) {
  // const {aITokens}= useContext(AITokenContext);
  // const [aITokenUsage] = aITokens;

  function refreshProfile() {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      handleSetProfile(JSON.parse(userInfo));
    }
  }
  useEffect(() => {
    refreshProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );

      console.log(userInfo);
      handleSetProfile(userInfo.data);
      localStorage.setItem("userInfo", JSON.stringify(userInfo.data));
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  useEffect(() => {
    async function handleUser() {
      if (profile?.email && profile?.given_name !== "Guest") {        
        let headers = getPostHeaders({ profile: profile }, "POST");
        const response = await fetch(
          `${process.env.REACT_APP_FASTNOTES_BE}/api/user`,
          { ...headers }
        );
        // if (response.status === 401) {context.logout({})}
        const data = await response.json();
        console.log(data);
        // var AITokensUsed = FetchAITokenUsage(profile?.email)
        // console.log("in CustomGoogleLoginButton.js")
        // console.log(aITokenUsage)        
      } else {
        refreshProfile();
      }
    }    
    handleUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    // localStorage.clear();
    localStorage.removeItem("userInfo");
    localStorage.removeItem("aiTokenUsage");
    handleSetProfile(null);
  };

  return (
    <>
      {profile?.email ? (
        <Container
          sx={{
            marginTop: "25px",
            float: "right",
            margin: "auto",
            width: "auto",
            padding: "18px",
            visibility:
              showLoading || !showTranscriptionCard ? "hidden" : "visible",
            display: showLoading || !showTranscriptionCard ? "none" : "",
          }}
        >
          <Tooltip title={`${profile?.name?.split(' ')[0]}, click to logout`} placement="bottom" onClick={logOut}>
            <Avatar alt={profile.name} src={profile ? profile.picture : ""} 
              sx={{
                width: "3rem",
                height: "3rem",

              }}/>
          </Tooltip>
        </Container>
      ) : (
        <Button
          onClick={googleLogin}
          sx={{
            marginTop: "25px",
            float: "right",
            margin: "auto",
            padding: "18px",
            // transform:"translate(-0%, -50%)",
            visibility:
              showLoading || !showTranscriptionCard ? "hidden" : "visible",
            display: showLoading || !showTranscriptionCard ? "none" : "",
          }}
        >
          Sign in with Google 🚀{" "}
        </Button>
      )}
    </>
  );
}
export default CustomGoogleLoginButton;
