import React, {useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { getPostHeaders } from "../utils/headers";

export default function GoButton({  
  profile,
  noteBuffer,
  handleUrlSummary,
  handleUrlTldr,
  handleUrlBulletPoints,
  handleSummarizedNotes,
  handleTLDRNotes,
  handleBulletPointNotes,
  processingOptions,
  showLoading,
  showTranscriptionCard,
  showPricingPage,
}) {
  const [isLoading, setIsLoading] = useState(false);
    
  function doWork() {    
    if (noteBuffer.startsWith("http")) {          
      setIsLoading(true);
      handleProcessUrlWork(noteBuffer);                               
    } else {    
      setIsLoading(true);      
      handleProcessNotes(noteBuffer);            
    }
  }

  // https://abcnews.go.com/US/wireStory/damage-us-debt-default-cascade-global-economy-99502165
  // http://127.0.0.1:8000/api/process_url?url=https://abcnews.go.com/US/wireStory/damage-us-debt-default-cascade-global-economy-99502165
  async function handleProcessUrlWork(url) {
    const uuid = localStorage.getItem("_og_id");
    const email = profile?.email ? profile?.email : `${uuid}@fastnotes.ai`;  
    let headers = getPostHeaders({
      email:email,
      url:url, 
      processingOptions: processingOptions},"POST");
    const response = await fetch(
      `${process.env.REACT_APP_FASTNOTES_BE}/api/process_url`,
      { ...headers }
    );
    const data = await response.json();
    setIsLoading(false);
    handleUrlSummary(data[0]);
    handleUrlTldr(data[1]);
    handleUrlBulletPoints(data[2]);
        
  }

  async function handleProcessNotes(data) {
    const uuid = localStorage.getItem("_og_id");
    const email = profile?.email ? profile?.email : `${uuid}@fastnotes.ai`;   
    let headers = getPostHeaders({ 
      notes: data, 
      processingOptions: processingOptions,
      email:email }, "POST");
    const response = await fetch(
      `${process.env.REACT_APP_FASTNOTES_BE}/api/process_notes`,
      { ...headers }
    );    
    const resp = await response.json();    
    setIsLoading(false);    
    handleSummarizedNotes(resp[0]);
    handleTLDRNotes(resp[1]);
    handleBulletPointNotes(resp[2]);    
  }

  return (
      <LoadingButton
        disabled={showPricingPage}
        loading={isLoading}
        variant="outlined"
        onClick={doWork}
        sx={{
          visibility:
            showLoading || !showTranscriptionCard ? "hidden" : "visible",
          display: showLoading || !showTranscriptionCard ? "none" : "",
        }}
      >
        Go
      </LoadingButton>

  );
}
