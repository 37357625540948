import React, { useEffect, useState, useContext } from "react";
import {
  Chip,
  Paper,
  BottomNavigationAction,
  BottomNavigation,
  Container,
  Box,
  Typography,
  Divider,
  Button,
  Skeleton,
  Snackbar,
  Alert,
  Grid,
} from "@mui/material";
import PaperNote from "./PaperNote";
import PolicyIcon from "@mui/icons-material/Policy";
import TwitterIcon from "@mui/icons-material/Twitter";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import CustomGoogleLoginButton from "./components/CustomGoogleLoginButton";
import { getGetHeaders, getPostHeaders } from "./utils/headers";
import { AITokenContext } from './AITokenContext'
import PricingPage from "./pages/PricingPage";
import axios from 'axios';
import ActionButtonGroup from "./components/ActionButtonGroup";
import YouTube from 'react-youtube';

function Landing() {
  const [showTranscriptionCard, setShowTranscriptionCard] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [noteBuffer, setNoteBuffer] = useState("");
  const [summarizedNotes, setSummarizedNotes] = useState([]);
  const [tldrNotes, setTLDRNotes] = useState([]);
  const [bulletPointNotes, setBulletPointNotes] = useState([]);
  const [noteType, setNoteType] = useState("");
  const [noteTone, setNoteTone] = useState("");
  const [showNoteType, setShowNoteType] = useState(false);
  const [showNoteTone, setShowNoteTone] = useState(false);
  const [processingOptions, setProcessingOptions] = useState([
    "AI Inline",
    "Summary",
    "TL;DR",
    "Bullet Points",
  ]);
  const [clearNotes, setClearNotes] = useState(false);
  const [pdfBytes, setPdfBytes] = useState([]);
  const [openPdfSnackbar, setOpenPdfSnackbar] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");
  const [profile, setProfile] = useState([]);
  const [urlSummary, setUrlSummary] = useState([]);
  const [urlTldr, setUrlTldr] = useState([]);
  const [urlBulletPoints, setUrlBulletPoints] = useState([]);
  const [revisedNote, setRevisedNote] = useState("");
  const [showPricingPage, setShowPricingPage] = useState(false);
  const {aITokens}= useContext(AITokenContext);
  const [aITokenUsage, setAITokenUsage] = aITokens;
  const matches = useMediaQuery("(min-width:800px)");

  
  function getTempEmail() {
    const uuid = localStorage.getItem("_og_id");
    const trialEmail = `${uuid}@fastnotes.ai`;
    return trialEmail;
}

  useEffect(() => {
    async function fetchit() {        
        const email = profile?.email ? profile?.email : getTempEmail();       
        await axios.get(`${process.env.REACT_APP_FASTNOTES_BE}/api/token_usage?email=${email}`,
                {responseType: 'json'})
                .then(response => {
                    console.log(`in hook, response.data is: ${response.data}`);
                    localStorage.setItem("aITokenUsage", response.data);
                    setAITokenUsage(response.data);  
                    if (response.data[2] <= 0) {
                      setProcessingOptions(processingOptions.filter((i) => i !== "AI Inline"));                      
                      setShowPricingPage(true);
                      
                    }
            }).catch(error => {
                console.error(`Error fetching token usage, ERROR: ${error}`);                
            });          
    }
    fetchit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revisedNote, summarizedNotes, tldrNotes, bulletPointNotes, urlSummary, urlTldr, urlBulletPoints, revisedNote, profile]);


  const handleSetAITokens = (value) => {
    console.log(`in handleSetAITokens setting tokens to ${value}`)
    setAITokenUsage(value);
  }

  const handleUrlSummary = (value) => {
    setUrlSummary(value);
  };
  const handleUrlTldr = (value) => {
    setUrlTldr(value);
  };
  const handleUrlBulletPoints = (value) => {
    setUrlBulletPoints(value);
  };

  function handleSetProfile(value) {
    setProfile(value);
  }

  function handlePrivacyPolicy() {
    setShowPrivacyPolicy(!showPrivacyPolicy);
  }

  function handleBulletPointNotes(value) {
    console.log("setting bullet points notes");
    setBulletPointNotes(value);
  }

  function handleTLDRNotes(value) {
    console.log("setting tldr notes");
    setTLDRNotes(value);
  }

  function handlePdfBytes(value) {
    console.log(`setting pdf bytes, value is ${value}`);
    setPdfBytes(value);
    handleDownloadLink(value);
    setOpenPdfSnackbar(true);
  }

  function handleClosePdfSnackbar(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpenPdfSnackbar(false);
  }



  function handleSubmit(event) {
    event.preventDefault();
    setShowTranscriptionCard(true);
    setShowLoading(false);
  }

  function handleNoteTypeDelete(item) {
    setShowNoteType(false);
  }
  function handleNoteToneDelete(item) {
    setShowNoteTone(false);
  }

  function handleProcessingOptionsDelete(item) {        
    setProcessingOptions(processingOptions.filter((i) => i !== item));
  }

  async function handleCurrentSentence(value) {
    var sentences = value.split("\n");
    var last_sentence = sentences[sentences.length - 1];
    const uuid = localStorage.getItem("_og_id");
    const email = profile?.email ? profile?.email : `${uuid}@fastnotes.ai`;    

    let headers = getPostHeaders(
      {
        email: email,
        data: last_sentence,
        processingOptions: processingOptions,
      },
      "POST"
    );
    const response = await fetch(
      `${process.env.REACT_APP_FASTNOTES_BE}/api/process_inline`,
      { ...headers }
    );
    // if (response.status === 401) {context.logout({})}    
    const data = await response.json();
    const revisedTextBlob =
    value.split("\n").slice(0, -1).join("\n") + "\n" + data + "\n";
    setRevisedNote(revisedTextBlob);  
  }

  function handleNoteType(value) {
    // if (value === 'URL') {
    //     setProcessUrl(true);
    // }
    console.log("setting note type");
    setNoteType(value);
    setShowNoteType(true);
  }

  function handleNoteTone(value) {
    console.log("setting note tone");
    setNoteTone(value);
    setShowNoteTone(true);
  }

  function handleProcessingOptions(value) {
    console.log("setting processing options", value);
    if (!processingOptions.includes(value)) {
      setProcessingOptions([...processingOptions, value]);
    }
    console.log(`set processing options: ${processingOptions}`);
  }

  useEffect(() => {
    console.log("processing options");
    console.log(processingOptions);
  }, [processingOptions]);

  function handleNoteBuffer(value) {
    console.log("setting note buffer");
    setNoteBuffer(...noteBuffer, value);
  }

  function handleSummarizedNotes(value) {
    console.log("setting summarized notes");
    setSummarizedNotes(value);
  }

  async function handleDownloadLink(value) {
    let headers = getGetHeaders("GET");
    const response = await fetch(
      `${process.env.REACT_APP_FASTNOTES_BE}/api/serve_pdf?pdf_name=${value}`,
      { headers }
    );
    // if (response.status === 401) {context.logout({})}
    const data = await response.json();
    setDownloadLink(data);
  }

  const letsGoMax = { lg: "100%", md: "100%", xs: "100%", sm: "100%" };
  const imgMax = { lg: "384px", md: "384px", xs: "324px", sm: "324px" };
  const pageImgMax = { lg: "84px", md: "84px", xs: "84px", sm: "84px" };
  const imgWidth = { lg: "384px", md: "384px", xs: "324px", sm: "324px" };
  const colLanding = { xs: 1, sm: 8, md: 12 };
  const colWorking = { xs: 8, sm: 8, md: 12 };
  const getColumnWidths = !showLoading && !showTranscriptionCard ? colLanding : colWorking;
  const getMaxWidth =
    !showLoading && !showTranscriptionCard ? imgMax : pageImgMax;
  const getHeaderBoxHeight =
    !showLoading && !showTranscriptionCard ? "auto" : "auto";
  const getHeaderBoxWidth =
    !showLoading && !showTranscriptionCard ? "fit-content" : "auto";
  const getLogoPadding = !showLoading && !showTranscriptionCard ? "0%" : "20%";
  const getLogoFloat =
    !showLoading && !showTranscriptionCard ? "right" : "left";
  const getLogoLeftPadding =
    !showLoading && !showTranscriptionCard
      ? { lg: "40px", md: "40px", xs: "0px", sm: "0px" }
      : "0px";
  // const getChipColor = (item) => {item === 'AI Inline' ? backgroundColor='yellow' : backgroundColor='warning'};
  const opts = matches ? { height: "390", width: "640" } : { height: "195", width: "320" };
  const containerStyle = !showLoading && !showTranscriptionCard ? { maxWidth: "100%", marginTop: "1%",  paddingLeft:"1rem", paddingRight:"1rem"} : { paddingBottom: "70px", marginBottom:"3rem",  maxWidth: "100%", marginTop: "1%",  paddingLeft:"1rem", paddingRight:"1rem"};

  return (
    <>    
    
      <Container
        sx={ containerStyle }
      >
        <Box
          sx={{
            width: "100%",
            margin: "auto",
            maxWidth: getHeaderBoxWidth,
            height: getHeaderBoxHeight,
          }}
        >
          <Grid container spacing={{ xs: 0, md: 3 }} columns={getColumnWidths}>
            <Grid item xs={2} md={!showLoading && !showTranscriptionCard ? 6: 'auto' } sm={!showLoading && !showTranscriptionCard ? 4: 'auto'}>
              <Box
                component="img"
                sx={{
                  height: "auto",
                  width: imgWidth,
                  maxWidth: getMaxWidth,                  
                  margin: "auto",
                  marginTop: ".7rem",
                  float: getLogoFloat,
                  paddingLeft: getLogoLeftPadding,
                  paddingRight: getLogoPadding,
                }}
                alt="FastNotes logo"
                src="./FastNotesLogo.png"
              />
            </Grid>

            <Grid
              item
              xs={4}
              md={8} 
              lg={8}
              sx={{
                marginTop: "3rem",
                
                visibility:
                  showLoading || !showTranscriptionCard ? "hidden" : "visible",
                display: showLoading || !showTranscriptionCard ? "none" : "",
              }}
            >
              <Chip
                label={noteType}
                color="primary"
                sx={{
                  marginBottom: "0px",
                  marginLeft: "5px",
                  marginTop: "1px",
                  transform: "translate(-0%, -50%)",
                  visibility:
                    showLoading || !showTranscriptionCard || !showNoteType
                      ? "hidden"
                      : "visible",
                  display:
                    showLoading || !showTranscriptionCard || !showNoteType
                      ? "none"
                      : "",
                }}
                onDelete={handleNoteTypeDelete}
              />
              <Chip
                label={noteTone}
                color="success"
                sx={{
                  marginBottom: "0px",
                  marginTop: "1px",
                  marginLeft: "5px",
                  transform: "translate(-0%, -50%)",
                  visibility:
                    showLoading || !showTranscriptionCard || !showNoteTone
                      ? "hidden"
                      : "visible",
                  display:
                    showLoading || !showTranscriptionCard || !showNoteTone
                      ? "none"
                      : "",
                }}
                onDelete={handleNoteToneDelete}
              />
              {processingOptions &&
                processingOptions.map((item, index) => (
                  <Chip
                    key={index}
                    label={item}
                    sx={{
                      marginBottom: "0px",
                      marginLeft: "5px",
                      marginTop: "1px",
                      transform: "translate(-0%, -50%)",
                      fontFamily: "Roboto, Helvetica,Arial sans-serif",
                      // fontSize: "0.8125rem",
                      backgroundColor:
                        item === "AI Inline" ? "purple" : "orange",
                      color: item === "AI Inline" ? "white" : "white",

                      visibility:
                        showLoading || !showTranscriptionCard
                          ? "hidden"
                          : "visible",
                      display:
                        showLoading || !showTranscriptionCard ? "none" : "",
                    }}
                    onDelete={() => handleProcessingOptionsDelete(item)}
                  />
                ))}
            </Grid>
           
            <Grid item={true}  xs={2}>            
              <Box
                component="img"
                sx={{
                  height: "auto",
                  width: "384",
                  // maxHeight: 'auto',
                  maxWidth: getMaxWidth,
                  marginBottom: "5%",
                  visibility:
                    showLoading || showTranscriptionCard ? "hidden" : "visible",
                  display: showLoading || showTranscriptionCard ? "none" : "",
                }}
                alt="app screenshot"
                src="./landing_screenshots.png"
              />              
              <CustomGoogleLoginButton
                showLoading={showLoading}
                showTranscriptionCard={showTranscriptionCard}
                profile={profile}
                handleSetProfile={handleSetProfile}                
              />
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            visibility:
              showLoading || !showTranscriptionCard ? "hidden" : "visible",
            display: showLoading || !showTranscriptionCard ? "none" : "",
            margin: "auto",
            width: "100%",
            maxWidth: "fit-content",
          }}
        ></Box>

        {!showLoading && !showTranscriptionCard && (
          <>
            <Divider
              variant="middle"
              sx={{ marginBottom: "2%", marginTop: "2px" }}
            />
            <Box
              sx={{ margin: "auto", width: "100%", maxWidth: "fit-content" }}
            >
              <Typography variant="h2" gutterBottom sx={{ fontSize: "3.35em" }}>
              Capture Effortlessly, Engage Fully! 
              </Typography>
            </Box>
          </>
        )}
        <Divider variant="middle" sx={{ marginBottom: "1%" }} />
        {!showLoading && !showTranscriptionCard && (
          <Box
            component="div"
            sx={{ margin: "auto", width: "100%", maxWidth: "fit-content" }}
          >
            <Box
              component="div"
              sx={{ margin: "auto", width: "100%", maxWidth: "fit-content" }}
            >
              <Typography variant="body1" gutterBottom>
                Ready to take meeting notes?
              </Typography>
            </Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              alignContent="center"
              sx={{
                width: "100%",
                maxWidth: "fit-content",
                margin: "auto",
              }}
            >
              <Box
                component="div"
                sx={{ margin: "auto", width: "100%", maxWidth: "fit-content" }}
              >
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  sx={{
                    width: letsGoMax,
                    fontSize: "2rem",
                    borderRadius: 7,
                    marginTop: "5%",
                    marginBottom: "25%",
                    backgroundColor: "#4786e4",
                  }}
                >
                  Let's Go!
                </Button>
              </Box>
            </Box>
            
          </Box>
          
        )}

        <Box sx={{ marginRight: "2em" }}>                
          {showLoading && (
            <Skeleton variant="rectangular" width={750} height={750} />
          )}
          {showTranscriptionCard && (
            <PaperNote
              showPricingPage={showPricingPage}
              ///////////////////////////////////////////////////////////////////////////////////////
              // As PaperNote is updated, the note buffer is updated
              handleNoteBuffer={handleNoteBuffer}
              ///////////////////////////////////////////////////////////////////////////////////////
              // Go button updates these state variables by calling the handle functions
              summarizedNotes={summarizedNotes}
              tldrNotes={tldrNotes}
              bulletPointNotes={bulletPointNotes}
              urlSummary={urlSummary}
              urlTldr={urlTldr}
              urlBulletPoints={urlBulletPoints}
              ///////////////////////////////////////////////////////////////////////////////////////
              // Passed down to AIContext menu that is rendered in PaperNote.js
              handleNoteTone={handleNoteTone}
              handleNoteType={handleNoteType}
              ///////////////////////////////////////////////////////////////////////////////////////
              // Processing options control adding/removing processing options chips by updating state variables
              handleProcessingOptions={handleProcessingOptions}
              ///////////////////////////////////////////////////////////////////////////////////////
              // Calls the backend api/process_inline endpoint to process the current line of text
              // Sets setNoteBuffer to the revised note. DO NOT KNOW IF THIS IS CORRECT
              // Also sets revisedNote to the revised note.
              handleCurrentSentence={handleCurrentSentence}
              ///////////////////////////////////////////////////////////////////////////////////////
              // Contains the AI revised note
              ///////////////////////////////////////////////////////////////////////////////////////
              revisedNote={revisedNote}
              // This seemed unneeded
              // handleRevisedPaperNote={handleRevisedPaperNote}
              ///////////////////////////////////////////////////////////////////////////////////////
              // PaperNote uses this state variable to determine if 'inline' processing is enabled
              // if it is, then a handler is added to listen for the enter key
              processingOptions={processingOptions}
              ///////////////////////////////////////////////////////////////////////////////////////
              clearNotes={clearNotes}
              handleSetAITokens={handleSetAITokens}
            />
          )}
          {showPrivacyPolicy && <PrivacyPolicy />}
          {showPricingPage && <PricingPage />}
        </Box>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openPdfSnackbar}
          onClose={handleClosePdfSnackbar}
          key={pdfBytes}
        >
          <Alert severity="success">
            PDF created! <a href={downloadLink}>Download</a>
          </Alert>
        </Snackbar>
        
        <ActionButtonGroup
          matches={matches}
          aITokenUsage={aITokenUsage}
          showPricingPage={showPricingPage}
          profile={profile}
          noteBuffer={noteBuffer}
          handleNoteBuffer={handleNoteBuffer}
          handleUrlSummary={handleUrlSummary}
          handleUrlTldr={handleUrlTldr}
          handleUrlBulletPoints={handleUrlBulletPoints}
          handleSummarizedNotes={handleSummarizedNotes}
          handleTLDRNotes={handleTLDRNotes}
          handleBulletPointNotes={handleBulletPointNotes}
          processingOptions={processingOptions}
          showLoading={showLoading}
          showTranscriptionCard={showTranscriptionCard}
          handleClearNotes={setClearNotes}
          handlePdfBytes={handlePdfBytes}
          handleSetAITokens={handleSetAITokens}
          summarizedNotes={summarizedNotes}
          tldrNotes={tldrNotes}
          bulletPointNotes={bulletPointNotes}
          urlSummary={urlSummary}
          urlTldr={urlTldr}
          urlBulletPoints={urlBulletPoints}
        />

       
      </Container>
      
      <Grid container columns={16} sx={{display: showLoading || showTranscriptionCard ? "none" : "",
                visibility: showLoading || showTranscriptionCard ? "hidden" : "visible"  }}>
              <Box
                sx={{ width: '100%',
                minWidth: '100%',
                display: 'flex',
                minHeight: '300px',
                alignItems: 'center',
                justifyContent: 'center', backgroundColor: "#4786e4", color:"white",                
               }}
              > 
                <Typography variant="h4" gutterBottom sx={{ padding: "3.35em" }}>
                Picture this: you're in a meeting, a lecture, or a brainstorming session. Instead of fumbling with your pen and paper or typing like a maniac, you can simply jot down a few words to capture the context and FastNotes.ai will do the rest.
                </Typography>
            </Box>
        </Grid>

        <Grid container columns={16} sx={{display: showLoading || showTranscriptionCard ? "none" : "",
                visibility: showLoading || showTranscriptionCard ? "hidden" : "visible"  }}>
              <Box
                sx={{ width: '60%',
                minWidth: '60%',
                margin: 'auto',
                display: 'flex',
                minHeight: '300px',
                alignItems: 'center',
                justifyContent: 'center', backgroundColor: "white", color:"black" }}
              > 
                             
                <YouTube 
                  videoId="o_743pawHc4" 
                  opts={opts}
                  style={{paddingTop:"2rem", paddingBottom:"2rem"}}/>              
            </Box>
        </Grid>

        <Grid container columns={16} sx={{display: showLoading || showTranscriptionCard ? "none" : "",
                visibility: showLoading || showTranscriptionCard ? "hidden" : "visible"  }}>
              <Box
                sx={{ width: '100%',
                minWidth: '100%',
                display: 'flex',
                minHeight: '300px',
                alignItems: 'center',
                justifyContent: 'center', backgroundColor: "#4786e4", color:"white",                
               }}
              > 
                <Typography variant="h4" gutterBottom sx={{ padding: "3.35em" }}>
                No more tedious transcriptions or boring editing sessions. Jot down your notes then sythesize them into a summary, TL;DR, or bullet points with a single click. Share them as a PDF with team or save them to your Google Drive.
                </Typography>
            </Box>
        </Grid>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation showLabels sx={{ backgroundColor: "#fff" }}> 
        {/* 4786e4 */}
          <BottomNavigationAction
            sx={{ color: "#4786e4" }}
            label="Privacy"
            icon={<PolicyIcon />}
            onClick={handlePrivacyPolicy}
          />
          <BottomNavigationAction
            sx={{ color: "#4786e4" }}
            label="Twitter"
            icon={<TwitterIcon />}
          />
        </BottomNavigation>
      </Paper>      
    </>
  );
}

export default Landing;
